export function sendEvent(args: eventArgs) {
  const gtag = (window as any).gtag;
  if (gtag && process.env.NODE_ENV === "production") {
    gtag("event", "error", {
      event_category: args.category,
      event_label: args.label,
      value: args.value,
    });
  }
}

type eventArgs = {
  category: EventCategory;
  label: string;
  value: string | number;
};

export enum EventCategory {
  CitasFormError = "Citas-Form-Error",
}
