import FormState from "../../types/form_state";
import ComplexAction from "./ComplexAction";

class OnChangeFormAction<Model> extends ComplexAction<
  FormState<Model>,
  { key: keyof Model; value: any },
  { key: keyof Model },
  { key: keyof Model; error: string }
> {
  callReducer = (
    state: FormState<Model>,
    { key, value }: { key: keyof Model; value: any }
  ): void => {
    state.model[key] = value;
  };

  successReducer = (state: FormState<Model>, { key }: { key: keyof Model }) => {
    delete state.errors[key];
  };

  failedReducer = (
    state: FormState<Model>,
    { key, error }: { key: keyof Model; error: string }
  ) => {
    state.errors[key] = error;
  };
}

export default OnChangeFormAction;
