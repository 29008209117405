interface IStatusCheck {
  status_id: 0 | 1 | 2 | 3
  status_name: StatusName;
  registerInfo: any
  timeLeft: number;
  appointmentInfo: AppointmentInfo;
  phase_name: string;
}

interface AppointmentInfo {
  appointmentId: number
  Center: {id: string, name: string}
  CenterId: string
  Date: string
  DateFormat: string
  createdAt: string
  id: number
}

export enum StatusName {
  UNREGISTERED = "UNREGISTERED",
  PHASE_NOT_ACTIVE = "PHASE_NOT_ACTIVE",
  WITHOUT_APPOINTMENT = "WITHOUT_APPOINTMENT",
  WITH_APPOINTMENT = "WITH_APPOINTMENT",
}


export default IStatusCheck;