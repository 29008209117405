import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import register from './register'

// The inital state. Will be merged with partials states.
const initState = {};

// Combine all partial reducers.
const rootReducer = combineReducers({
  register
});

const middleware = [thunk];

// Create reduc store of all existing stores. Also init devtools.
const store = createStore(
  rootReducer,
  initState,
  process.env.NODE_ENV === "development" ? composeWithDevTools(applyMiddleware(...middleware)) : undefined
);

export { store };
