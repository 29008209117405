import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2vh",
    color: 'white'
  },
  progress: {
    color: '#ad1457',
  }
}));

export default useStyles;
