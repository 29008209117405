import axios, { AxiosInstance, AxiosResponse } from "axios";
import { IProvince, ITown, ICenter } from "src/types";
import IVaccination from "../../types/IVaccination";
import IStatusCheck from "../../types/IStatusCheck";

class ServerManager {
  apiAxios: AxiosInstance;
  dataAxios: AxiosInstance;

  constructor() {
    this.apiAxios = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    this.dataAxios = axios.create({
      baseURL: process.env.REACT_APP_API2_URL,
    });
  }

  refreshInstance() {
    /*this.axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response?.status === 401) {
        window.clearAuthData();
      }
      return error;
    })*/
  }

  getCenterAvailability(
    center: string
  ): Promise<AxiosResponse<{ result: string[] }>> {
    const url = `/api/v1/data/vaccine/available/?center__id=${center}`;
    return this.dataAxios.get<{ result: string[] }>(url);
  }

  async checkStatus(id: string): Promise<IStatusCheck | false> {
    const url = "/appointment/check-status";
    try {
      const result = await this.apiAxios.post<IStatusCheck>(url, {
        id,
      });
      return result.data;
    } catch (e) {
      return false;
    }
  }

  async checkMinorStatus(id: string): Promise<IStatusCheck | false> {
    const url = "/minor/check-status";
    try {
      const result = await this.apiAxios.post<IStatusCheck>(url, {
        id,
      });
      return result.data;
    } catch (e) {
      return false;
    }
  }

  async restoreKey(
    Name: string,
    TutorId: string,
    BirthDate: string
  ): Promise<IStatusCheck | false> {
    const url = "/minor/restore-key";
    try {
      const result = await this.apiAxios.post<IStatusCheck>(url, {
        Name,
        TutorId,
        BirthDate,
      });
      return result.data;
    } catch (e) {
      return false;
    }
  }

  async restoreAdultKey(
    Id: string,
    BirthDate: string
  ): Promise<IStatusCheck | false> {
    const url = "/appointment/restore-key";
    try {
      const result = await this.apiAxios.post<IStatusCheck>(url, {
        Id,
        BirthDate,
      });
      return result.data;
    } catch (e) {
      return false;
    }
  }

  loadProvincesByName(
    name: string
  ): Promise<AxiosResponse<{ count: number; results: IProvince[] }>> {
    const url = `/api/v1/data/province/?name__icontains=${name}&limit=50&enable_citation=true`;
    return this.dataAxios.get<{ count: number; results: IProvince[] }>(url);
  }

  loadTowns(
    name: string,
    provinceId?: string
  ): Promise<AxiosResponse<{ count: number; results: ITown[] }>> {
    let url = `/api/v1/data/town/?name__icontains=${name}&limit=50&enable_citation=true`;
    if (provinceId) url = url.concat(`&province=${provinceId}`);
    return this.dataAxios.get<{ count: number; results: ITown[] }>(url);
  }

  loadCenters(
    name: string,
    townId?: string
  ): Promise<AxiosResponse<{ count: number; results: ICenter[] }>> {
    let url = `/api/v1/data/center/?name__icontains=${name}&limit=50&enable_citation=true`;
    if (townId) url = url.concat(`&municipality=${townId}`);
    return this.dataAxios.get<{ count: number; results: ICenter[] }>(url);
  }

  createVaccination(args: any): Promise<AxiosResponse<IVaccination>> {
    return this.apiAxios.post<IVaccination>(`/vaccination`, args);
  }

  /**
   * Check if exist an entrie with the provided indentification number
   * @param id Identification number to check
   * @returns true if exist the entrie.
   */
  async checkIfExistVaccination(id: string): Promise<boolean | any> {
    try {
      const url = `/vaccination/check`;
      const result = await this.apiAxios.post(url, {
        Id: id,
      });
      return result.data;
    } catch (e) {
      return false;
    }
  }

  async getTimeLeft(id: string): Promise<boolean | number | undefined> {
    try {
      const url = "/vaccination/timeleft";
      const response = await this.apiAxios.post(url, {
        Id: id,
      });
      return false;
    } catch (e: any) {
      if (e.response?.status === 412) {
        return e?.response?.data?.timeLeft as number;
      }
    }
  }

  async validateWithCitizen(
    id: string
  ): Promise<false | { name: string; age: number }> {
    const url = `/appointment/validate`;
    const result = await this.apiAxios.post(url, {
      id,
    });
    if (result.data && result.status && result.status === 200) {
      const data = result.data;
      if (!data.valid) return false;
      return {
        name: data.name,
        age: data.age,
      };
    } else return false;
  }
}

export default ServerManager;
