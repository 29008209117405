import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  fieldLabel: {
    fontSize: "16px",
    fontWeight: 500
  },
  margin: {
    margin: theme.spacing(1),
  },
  select: {},
  helperText: {
    marginLeft: theme.spacing(2)
  },
  container: {
    minHeight: "500px",
  },
  paper: {
    padding: theme.spacing(2),
    width: "100%",
    borderRadius: "10px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
    },
  },
  btnContainers:{
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
   paddingBottom: theme.spacing(3),
  },
  btn:{
    margin: 'auto',
  },
  registrate: {
    color: theme.palette.primary.main,
    fontSize: "28px",
    fontWeight: 900,
    lineHeight: "24px",
    letterSpacing: "0.11px",
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: "8px",
    marginBottom: '0.35em'
  },
  p: {
    color: "#001733",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0",
    lineHeight: "22px",
    textAlign: "center",
    maxWidth: "400px"
  },
}));

export default useStyles;