import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
  Slide,
  Typography,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { IdentityType, registerActions } from 'src/redux/register/actions';
import Alert from '../../Alert';
import moment from 'moment';
import 'moment/locale/es-do';
import * as GA from '../../../apis/GoogleAnalytics';
import { useServerManager } from '../../ServerManagerProvider/index';
import { TransitionProps } from '@material-ui/core/transitions';
import { useTypedSelector } from '../../../redux';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Step4(props: ConfirmationStepProps) {
  const dispatch = useDispatch();
  const {
    model: {
      provinceName,
      townName,
      center,
      centerName,
      name,
      identification,
      age,
      pasaporte,
      identityType,
      date,
      id,
      minorKey,
    },
  } = useTypedSelector((store) => store.register);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [withoutAvailability, setWithoutAvailability] =
    useState<boolean>(false);

  const serverManager = useServerManager();

  const dateF = useMemo(() => {
    return moment(date).format('LL');
  }, [date]);

  const sendData = () => {
    setError(false);
    setLoading(true);
    let modelId = age >= 18 ? 'AppointmentId' : 'MinorAppointmentId';
    serverManager.createVaccination({
      Age: age,
      Name: name,
      Date: date,
      [modelId]: id,
      CenterId: center,
    })
      .then((response) => {
        const DateFormat = response?.data?.DateFormat;
        dispatch(
          registerActions.onChange.call({
            key: 'dateFormat',
            value: DateFormat,
          })
        );
        props.onNextStep();
      })
      .catch((e: any) => {
        if (e.response && e.response?.data) {
          const { data } = e.response;
          if (
            data ==
            'There are no vaccinations for the center and the date given'
          ) {
            setWithoutAvailability(true);
            return;
          }
        }
        if (e.response) {
          setErrorCode(e.response.status);
        } else {
          setErrorCode('OFFLINE');
        }

        GA.sendEvent({
          category: GA.EventCategory.CitasFormError,
          label: `Form user: ${identification}`,
          value: 1,
        });

        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    sendData();
  };

  const handleClose = () => {
    setError(false);
    setErrorCode('');
  };

  const getIdentification = () => {
    let isMinor = age < 18;
    let label = '';
    let value = '';

    if (isMinor) {
      label = 'Clave única';
      value = minorKey;
    } else {
      label =
        identityType === IdentityType.CEDULA
          ? 'Cédula de Identidad y Electoral:'
          : 'Pasaporte:';
      value = identityType === IdentityType.CEDULA ? identification : pasaporte;
    }
    return (
      <Box mt={1}>
        <label>
          <b>{label}</b>
        </label>
        <Typography variant="subtitle1">{value}</Typography>
      </Box>
    );
  };

  return (
    <div>
      <Dialog
        open={withoutAvailability}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="xs"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            style={{ maxWidth: '75px' }}
          >
            <Error color="secondary" style={{ minWidth: 75, minHeight: 75 }} />
          </motion.div>
          <h2>Producto de alta demanda</h2>
          <p>
            Se agotaron las vacunas para el día que había seleccionado. Favor de
            escoger otro día.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onBackStep} color="primary">
            Seleccionar otro dia
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={error}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            style={{ maxWidth: '75px' }}
          >
            <Error color="secondary" style={{ minWidth: 75, minHeight: 75 }} />
          </motion.div>
          <h2>Error inesperado</h2>
          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
            Código de error: {errorCode}
          </h3>
          <p>Inténtelo más tarde.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{ minWidth: '100%' }}
        >
          {' '}
          <Box my={2}>
            <Alert
              severity="error"
              text="Por favor, verifique los datos de su cita."
            />
          </Box>
          <Box mt={3}>
            <label>
              <b>Fecha de primera vacunación</b>
            </label>
            <Typography variant="subtitle1">{dateF}</Typography>
          </Box>
          <Box mt={1}>
            <label>
              <b>Nombre</b>
            </label>
            <Typography variant="subtitle1">{name}</Typography>
          </Box>
          {getIdentification()}
          <Box mt={1}>
            <label>
              <b>Edad</b>
            </label>
            <Typography variant="subtitle1">{age}</Typography>
          </Box>
          <Box mt={1}>
            <label>
              <b>Centro de vacunación</b>
            </label>
            <Typography variant="subtitle1">{centerName}</Typography>
          </Box>
          <Box mt={1}>
            <label>
              <b>Provincia del centro de vacunación</b>
            </label>
            <Typography variant="subtitle1">{provinceName}</Typography>
          </Box>
          <Box mt={1}>
            <label>
              <b>Municipio del centro de vacunación</b>
            </label>
            <Typography variant="subtitle1">{townName}</Typography>
          </Box>
        </motion.div>
      </Box>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 1,
          delay: 0.5,
          staggerChildren: 0.3,
          delayChildren: 0.5,
        }}
      >
        <Box mt={4}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Button
                disabled={loading}
                onClick={props.onBackStep}
                size="large"
                variant="outlined"
                fullWidth
                color="primary"
              >
                Ir Atrás
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                disabled={loading}
                onClick={handleSubmit}
                size="large"
                fullWidth
                color="primary"
                variant="contained"
              >
                Finalizar
              </Button>
            </Grid>
          </Grid>
        </Box>
        {loading && <LinearProgress variant="indeterminate" />}
      </motion.div>
    </div>
  );
}

interface ConfirmationStepProps {
  onBackStep: () => void | any;
  onNextStep: () => void | any;
  title: string;
}

export default Step4;
