import FormState from "../../types/form_state";
import ComplexAction from "./ComplexAction";

class SubmitFormAction<ModelType> extends ComplexAction<
  FormState<ModelType>,
  null,
  null,
  Record<keyof ModelType, string>
> {
  callReducer = (): void => {};

  failedReducer = (
    state: FormState<ModelType>,
    payload: Partial<Record<keyof ModelType, string>>
  ): void => {
    Object.assign(state.errors, payload);
  };

  successReducer = () => {};
}

export default SubmitFormAction;
