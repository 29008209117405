import React, { PropsWithChildren } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import useStyles from "./LoadingIndicatorStyles";

function LoadingIndicator(props: LoadingIndicatorProps) {
  const classes = useStyles();

  const children = props.children ? props.children : "Cargando...";

  return (
    <div className={classes.loading}>
      <CircularProgress className={classes.progress}/>
      <Typography style={{color: props.color}}>{children}</Typography>
    </div>
  );
}

interface LoadingIndicatorProps extends PropsWithChildren<any> {
  color?: "black" | "white"
}

export default LoadingIndicator;
