import { makeStyles, Theme } from "@material-ui/core";
import background from "../../assets/images/background_image.png";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: theme.palette.primary.light,
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    paddingBottom: `${theme.spacing(2)}px`,
  },
  container: {
    minHeight: `calc(100vh - 68px - 32px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      margin: `0px ${theme.spacing(1)}px`,
      width: 'auto',
    }
  },
  appbar: {
    border: "none"
  },
  toolbar: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexGrow: {
    flexGrow: 1
  },
  icons: {
    color: "white"
  }
}));

export default useStyles;
