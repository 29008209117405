import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/es-do';
import { motion } from 'framer-motion';
import useStyles from './Style';
import 'moment/locale/es-do';
import { useTypedSelector } from '../../redux';
import { useServerManager } from '../../components/ServerManagerProvider';
import BirthDCalendar from '../../components/Calendar/Calendar';
import Fieldset from '../../components/Fieldset';
import TextMaskCustom from '../../components/TaxtMask';

import SimpleCard from '../../components/Card';
import SelectAgeRange from 'src/components/SelectAgeRange';

import logo from '../../assets/images/Logos/Vacunaterd.png';

function Form() {
  const { minorModel } = useTypedSelector((store) => store.register);
  const classes = useStyles();
  const history = useHistory();
  const [openMissingAnswersAlert, setOpenMissingAnswersAlert] = useState(false);
  const [minorNameError, setMinorNameError] = useState<any>({
    bool: false,
    message: '',
  });
  const [minorLastNameError, setMinorLastNameError] = useState<any>({
    bool: false,
    message: '',
  });
  const [minorBirthD, setMinorBirthD] = useState<any>(minorModel.BirthD);
  const [minorAge, setMinorAge] = useState<number>(minorModel.age);
  const [minorBDError, setMinorBDError] = useState<any>({
    bool: false,
    message: '',
  });
  const [minorKey, setKey] = useState<any>(null);
  const [form, setForm] = useState({
    minorName: '',
    minorLastName: '',
    tutorid: '',
    birth: '',
  });

  const [date, setDate] = useState<any>(new Date());

  const [isMinor, setIsMinor] = useState(false);
  const serverManager = useServerManager();

  const onFormChange = (event: any) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const getData = (event: any) => {
    event.preventDefault();
    if (isMinor) {
      getKey(form.minorName + ' ' + form.minorLastName, form.tutorid, date);
    } else {
      getAdultKey();
    }
  };

  const getKey = async (name: string, tutor: string, birth: string) => {
    const response: any = await serverManager.restoreKey(name, tutor, birth);

    [response].map((result: any) => {
      setKey(result);
    });
  };

  const getAdultKey = async () => {
    const response: any = await serverManager.restoreAdultKey(
      form.tutorid,
      date
    );
    setKey(response);
  };

  const closeMissingAnswersAlert = () => {
    setOpenMissingAnswersAlert(false);
  };
  const setMinorBirthDHandler = (date: any): void => {
    setMinorBDError({ bool: false, message: '' });
    const dateNow = moment(new Date());
    const selectedDate = moment(date);
    const minorAge = dateNow.diff(selectedDate, 'years');
    setMinorBDError({
      bool: false,
      message: '',
    });
    if (isMinor) {
      if (minorAge < 12 || minorAge > 18) {
        setMinorBDError({
          bool: true,
          message: 'El Menor debe de tener entre 12 años hasta 17 años',
        });
      }
    } else {
      if (minorAge < 18) {
        setMinorBDError({
          bool: true,
          message: 'Como adulto debes de ser mayor a 18 años',
        });
      }
    }
    setMinorAge(minorAge);
    setMinorBirthD(date);
  };

  useEffect(() => {
    let resetForm = {
      minorName: '',
      minorLastName: '',
      tutorid: '',
      birth: '',
    };
    setDate(new Date());
    setForm(resetForm);
  }, [isMinor]);

  const checkDateMinor = (): boolean => {
    const dateNow = moment(new Date());
    const selectedDate = moment(minorBirthD);
    const dateCondition =
      dateNow.format('DD/MM/yyyy') === selectedDate.format('DD/MM/yyyy');
    const minorAge = dateNow.diff(selectedDate, 'years');
    const minorAgeCondition = minorAge >= 12 && minorAge < 18;

    if (dateCondition) {
      setMinorBDError({
        bool: true,
        message: 'Este campo es obligatorio.',
      });
      return false;
    } else if (minorAgeCondition) {
      setMinorBDError({ bool: false, message: '' });
      return true;
    }

    setMinorBDError({
      bool: true,
      message: 'El Menor debe de tener entre 12 años hasta 17 años',
    });

    return false;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      py={[1, 5]}
      px={[1, 7]}
      justifyContent="center"
      alignItems="center"
    >
      <img src={logo} alt="" />
      <>
        <Box mt={1}>
          <h6 className={classes.registrate}>¡Recupere Su Clave!</h6>
        </Box>
        {/* <p className={classes.p}>
            Ahora complete la información correspondiente a su cita en el
            Programa Nacional de Vacunación contra el COVID-19.
          </p> */}
      </>

      <div style={{ width: '100%', marginTop: '16px' }}>
        <Dialog
          open={openMissingAnswersAlert}
          onClose={closeMissingAnswersAlert}
          maxWidth="xs"
        >
          <DialogTitle>Campos obligatorios</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Antes de continuar debe completar todos los campos obligatorios.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeMissingAnswersAlert}>Cerrar</Button>
          </DialogActions>
        </Dialog>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <SelectAgeRange isMinor={isMinor} setIsMinor={setIsMinor} />

          {isMinor && (
            <Fieldset title="DATOS DEL MENOR">
              <Box my={3} mx={2}>
                <label className={classes.fieldLabel}>Nombre</label>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    required
                    error={minorNameError.bool}
                    helperText={
                      minorNameError.bool ? minorNameError.message : ''
                    }
                    placeholder="Nombre"
                    name="minorName"
                    variant="outlined"
                    InputProps={{ inputProps: { minlength: 3, maxlength: 21 } }}
                    onChange={onFormChange}
                  ></TextField>
                </FormControl>
              </Box>

              <Box my={3} mx={2}>
                <label className={classes.fieldLabel}>Apellido</label>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    required
                    error={minorLastNameError.bool}
                    helperText={
                      minorLastNameError.bool ? minorLastNameError.message : ''
                    }
                    placeholder="Apellido"
                    name="minorLastName"
                    variant="outlined"
                    InputProps={{ inputProps: { minlength: 2, maxlength: 21 } }}
                    onChange={onFormChange}
                  ></TextField>
                </FormControl>
              </Box>
              <Box my={3} mx={2} display="flex" flexDirection="column">
                <label className={classes.fieldLabel}>
                  Fecha de Nacimiento
                </label>
                <BirthDCalendar
                  name="birth"
                  birthday={setMinorBirthDHandler}
                  error={minorBDError}
                  onChange={onFormChange}
                  date={date}
                  setDate={setDate}
                />
              </Box>
            </Fieldset>
          )}

          <Fieldset title={isMinor ? 'Tutor' : ''}>
            {!isMinor && (
              <Box my={3} mx={2} display="flex" flexDirection="column">
                <label className={classes.fieldLabel}>
                  Fecha de Nacimiento
                </label>
                <BirthDCalendar
                  name="birth"
                  birthday={setMinorBirthDHandler}
                  error={minorBDError}
                  onChange={onFormChange}
                  date={date}
                  setDate={setDate}
                />
              </Box>
            )}
            <Box my={3} mx={2}>
              <label className={classes.fieldLabel}>
                {isMinor
                  ? 'Identificación del tutor'
                  : 'Cédula de identidad electoral'}
              </label>
              <OutlinedInput
                required={true}
                name="tutorid"
                fullWidth
                value={form.tutorid}
                placeholder="000-0000000-0"
                inputProps={{ inputProps: { minlength: 2, maxlength: 13 } }}
                onChange={onFormChange}
                inputComponent={TextMaskCustom as any}
              />
            </Box>
          </Fieldset>

          <Box mt={4}>
            <Grid className={classes.btnContainers} container xs={5}>
              <Button
                // fullWidth
                className={classes.btn}
                size="large"
                color="primary"
                variant="outlined"
                onClick={() => history.push('/')}
              >
                ir atrás
              </Button>
              <Button
                // fullWidth
                className={classes.btn}
                size="large"
                color="primary"
                variant="contained"
                onClick={getData}
              >
                Recuperar Clave
              </Button>
            </Grid>
          </Box>
          {isMinor ? (
            <Box mt={4}>
              {minorKey?.count > 0 &&
                minorKey.rows.map((result: any, index: number) => {
                  if (minorKey.count > 1) {
                    return (
                      <SimpleCard
                        MinorKey={result.UniqueKey}
                        identification={result.Identification}
                        key={index}
                      />
                    );
                  }
                  return <SimpleCard MinorKey={result.UniqueKey} key={index} />;
                })}
            </Box>
          ) : (
            <Box mt={4}>
              {Boolean(minorKey) && (
                <SimpleCard MinorKey={minorKey.UniqueKey} />
              )}
            </Box>
          )}
        </motion.div>
      </div>
    </Box>
  );
}
export default Form;
