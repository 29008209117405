import { Box, Button} from "@material-ui/core";
import useStyles from "./styles"

interface SelectAgeRangeProps {
    isMinor:boolean;
    setIsMinor: Function;
}

const SelectAgeRange = ({isMinor, setIsMinor}: SelectAgeRangeProps)=>{
    const classes = useStyles()
    return (
        <Box my={2} 
        className={classes.btnsContainer}
        >
        <Button
          color={isMinor ? "inherit" : "primary"}
          variant={isMinor ? "outlined" : "contained"}
          className={classes.btn}
          onClick={() => {
            setIsMinor(false);
          }}
        >
          Adulto
        </Button>
        <Button
          color={isMinor ? "primary" : "inherit"}
          variant={isMinor ? "contained" : "outlined"}
          className={classes.btn}
          onClick={() => {
            setIsMinor(true);
          }}
        >
          Menor
        </Button>
      </Box>
    )
}

export default SelectAgeRange