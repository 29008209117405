import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles({
  root: {
    width: "28px",
    height: "28px",
    border: "1px solid #707070",
    borderRadius: "14px",
    textAlign: "center"
  },
  active: {
    borderColor: '#286DBD',
    backgroundColor: "#286DBD"
  },
  span: {
    verticalAlign: "middle",
    fontSize: "14px",
    lineHeight: "28px"
  },
  spanActive: {
    color: "white"
  }
});
