import React from "react";
import MaskedInput from "react-text-mask";

function TextMaskCustom(props: TextMaskCustomProps): React.ReactNode {
  const { inputRef, ...other } = props;

  const mask = [
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
  ];

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      showMask
      guide={false}
    />
  );
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

export default TextMaskCustom;
