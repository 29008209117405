import React from "react";
import {Box, Button, Container, Fade, Paper, Typography, useMediaQuery,} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {motion} from "framer-motion";
import useStyles from "./styles";
import {Info} from "@material-ui/icons";
import clsx from "clsx";
import {SHOW_INFORMATIVE_STEP} from "../../Config";
import theme from "../../theme";


function InformationRoute() {
  const history = useHistory();
  const classes = useStyles();

  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  if (!SHOW_INFORMATIVE_STEP)
    history.push("/")

  return (
    <Fade in>
      <Container maxWidth="sm"
                 style={{
                   padding: matches ? 0 : 16,
                 }}>
        <Paper className={classes.paper} style={{maxWidth: "100%"}}>

          <motion.div
            initial={{opacity: 0, y: 100}}
            animate={{opacity: 1, y: 0}}
            transition={{
              duration: 1,
              staggerChildren: 0.3,
              delayChildren: 0.5,
            }}
            style={{display: "flex", flexDirection: "column", alignItems: "center"}}
          >
            <Info style={{minWidth: "5rem", minHeight: "5rem"}} color="primary"/>

            <Box mt={1}>
              <Typography
                variant="h6"
                style={{
                  color: "#1F305B",
                  fontSize: "21",
                  fontWeight: "bold",
                  letterSpacing: "0.6px",
                  lineHeight: "25px",
                  textAlign: "center",
                }}
              >
                AVISO IMPORTANTE
              </Typography>
            </Box>
            <Box mt={1}>
              <p className={clsx(classes.p, classes.center)}>
                <b>En estos momentos nos encontramos atendiendo solamente personas de segunda dosis.</b>
              </p>
              <p className={classes.p}>
                Le invitamos a mantenerse atento a las informaciones de VacúnateRD para agendar su
                cita cuando le corresponda.
                <br/>
                <br/>
                Para mayor información y actualización sobre el proceso de vacunación, visite la
                página web {" "}
                <a href="https://vacunate.gob.do/">https://vacunate.gob.do/</a>
              </p>
            </Box>
            <Box mt={3}
                 display="flex"
                 flexDirection="column"
                 alignItems="center">
              <Button onClick={() => history.push("/")}
                      variant="outlined">
                Regresar al inicio
              </Button>
            </Box>
          </motion.div>
        </Paper>
      </Container>
    </Fade>
  );
}

export default InformationRoute;
