import React, { useEffect, useState } from "react";
import Calendar, { CalendarTileProperties } from "react-calendar";
import { useDispatch } from "react-redux";
import "moment/locale/es-do";
import { Box, Button, Grid, } from "@material-ui/core";
import { motion } from "framer-motion";

import { differenceInCalendarDays } from "date-fns";
import axios from "axios";
import Alert from "../../Alert";
import { registerActions } from "../../../redux/register/actions";

import StatusMessage from "../../StatusMessage";
import LoadingIndicator from "../../LoadingIndicator";
import { useTypedSelector } from "../../../redux";
import "./BookingStepCalendar.css";
import {getDaysFromToday, isSameDay} from "../../../utils/Date";
import {useServerManager} from "../../ServerManagerProvider";

function Step3(props: BookingStepProps) {
  const {
    model: { center },
  } = useTypedSelector(store => store.register);
  const dispatch = useDispatch();
  const serverManager = useServerManager();
  const [availability, setAvailability] = useState<Date[]>();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<Date>(new Date());

  const tileDisabled = (
    args: CalendarTileProperties & { activeStartDate: Date }
  ): boolean => {
    const { date, view } = args;
    if (view === "month" && availability)
      return (
        (availability as any).find((dDate: Date) => {
          return isSameDay(dDate, date);
        }) === undefined
      );
    return false;
  };

  useEffect(() => {
    if (!availability) {
      serverManager.getCenterAvailability(center)
        .then((response) => {
          const { data } = response;
          if (data) {
            let { result } = data;
            let dates = getDaysFromToday(result);
            setStartDate(dates.sort()[0])
            setAvailability(dates);
            setLoading(false);
          }
        });
    }
  });

  const handleSelection = (value: any) => {
    dispatch(
      registerActions.onChange.call({
        key: "date",
        value: value,
      })
    );
    props.onNextStep();
  };

  const hasAvailability = () => {
    if (loading) return null;
    return availability != null && Boolean((availability as any).length);
  };

  const renderStatus = () => {
    if (!hasAvailability())
      if (loading) return <LoadingIndicator color="black" />
      else
        return (
          <StatusMessage
            title="¡SEGUIMOS VACUNANDO!"
            disableElevation
          >
            En línea con nuestra meta de inmunizar en el menor tiempo posible a todos los dominicanos, hemos sobrepasado
            la cantidad de personas a vacunar en el centro de vacunación seleccionado. Actualmente estamos abasteciendo
            con más vacunas este puesto y tan pronto terminemos este proceso usted podrá hacer su cita. En caso de que
            no desee esperar, le ofrecemos la opción de elegir otro centro de vacunación.
            <br />
            <br />
            ¡Gracias por ser parte de VacúnateRD!
          </StatusMessage>
        );
  };
  const onBack = () => {
    dispatch(registerActions.onChange.call({ key: "province", value: "" }))
    dispatch(registerActions.onChange.call({ key: "town", value: "" }))
    dispatch(registerActions.onChange.call({ key: "center", value: "" }))
    dispatch(registerActions.onChange.call({ key: "provinceName", value: "" }))
    dispatch(registerActions.onChange.call({ key: "townName", value: "" }))
    dispatch(registerActions.onChange.call({ key: "centerName", value: "" }))
    props.onBackStep();
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, staggerChildren: 0.3, delayChildren: 0.5 }}
        >
          {Boolean(props.error) && <Alert severity="error" text={props.error} />}

          {hasAvailability() ? (
            <Box mt={1}>
              <Calendar
                locale="es"
                view="month"
                defaultView="month"
                defaultActiveStartDate={startDate}
                tileDisabled={tileDisabled}
                onChange={handleSelection}
              />

              <div className="legend">
                <svg data-layer="7dd8c4db-44d9-4311-8cc3-abc9a120c664" preserveAspectRatio="none" viewBox="0 0 16 16"
                  className="dot">
                  <path
                    d="M 8 0 C 12.41827774047852 0 16 3.581722259521484 16 8 C 16 12.41827774047852 12.41827774047852 16 8 16 C 3.581722259521484 16 0 12.41827774047852 0 8 C 0 3.581722259521484 3.581722259521484 0 8 0 Z" />
                </svg>
                <div>
                  Días disponibles
                </div>
              </div>

            </Box>
          ):(
            <Alert severity="error" text="Este centro no tiene dias disponibles para citas."/>
          )
        }

          {renderStatus()}
        </motion.div>

      </Box>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 1,
          delay: 0.5,
          staggerChildren: 0.3,
          delayChildren: 0.5,
        }}
      >
        <Box mt={4}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Button
                onClick={onBack}
                size="large"
                variant="outlined"
                fullWidth
                color="primary"
              >
                Ir Atrás
              </Button>
            </Grid>
            <Grid item sm={6}></Grid>
          </Grid>
        </Box>
      </motion.div>
    </>
  );
}

export interface BookingStepProps {
  onNextStep: () => void | any;
  onBackStep: () => void | any;
  title: string;
  error: string;
}

export default Step3;
