import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useTypedSelector } from '../../redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

moment.locale('es-do');

function BirthDCalendar(props: any) {
  const { birthday, error, date, setDate } = props;
  const locale = 'es-do';
  const setBDay = (birthd: any) => {
    let tempDate = birthd.toISOString().split('T')[0]
    birthday(tempDate);
    setDate(tempDate);
  };
  

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale}
    >
      <DatePicker
        error={error.bool}
        required
        helperText={error.bool ? error.message : ''}
        disableFuture
        openTo="year"
        format="DD/MM/yyyy"
        views={['year', 'month', 'date']}
        value={date}
        onChange={setBDay}
      />
    </MuiPickersUtilsProvider>
  );
}
export default BirthDCalendar;