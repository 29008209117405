import { Box, Typography } from "@material-ui/core";
import Alert from "../Alert";
import { motion } from "framer-motion";
import { useMemo } from "react";
import confirmacion from "../../assets/images/confirmacion.png";
import moment from "moment";
import {useTypedSelector} from "../../redux";

function Finish(props: FinishStepProps) {
  const {
    model: {
      centerName,
      name,
      date,
    },
  } = useTypedSelector(store => store.register);


  const dateF = useMemo(() => {
    return moment(date).format("LL");
  }, [date]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <motion.img
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          src={confirmacion}
          alt=""
        />
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 1,
            staggerChildren: 0.3,
            delayChildren: 0.5,
          }}
        >
          <Box mt={4}>
            <Typography
              variant="h6"
              style={{
                color: "#1F305B",
                fontSize: "21",
                fontWeight: "bold",
                letterSpacing: "0.6px",
                lineHeight: "25px",
                textAlign: "center",
              }}
            >
              Plan #VacúnateRD
              <br />
              ¡Ya tiene cita para vacunarse!
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography
              variant="h6"
              style={{
                color: "#1F305B",
                fontSize: "19",
                fontWeight: "bold",
                letterSpacing: "0.6px",
                lineHeight: "25px",
                textAlign: "center",
              }}
            >
              {name}:
            </Typography>
          </Box>
          <Box mt={1}>
            <p
              style={{
                color: "#1F305B",
                fontSize: "16px",
                letterSpacing: "0.46px",
                textAlign: "justify",
                lineHeight: "28px",
              }}
            >
              Su cita ha sido programada con éxito en el Centro de Vacunación{" "}
              <b>{centerName}</b>, para el día <b>{dateF}</b>.
              <br />
              Debe presentarse con su documento de identidad entre las 9:00 A.M. y 12:00 P.M. el
              día y lugar correspondiente. Pasado el mediodía, perderá su cita
              asignada.
              <br />
              <br />
              Para mayor información sobre el Plan #VacúnateRD y sus fases, le
              invitamos a visitar la página web{" "}
              <a href="https://vacunate.gob.do">vacunate.gob.do</a>
              <br />
              <br />
              <div style={{ textAlign: "center" }}>
                ¡Gracias por sumarse a esta misión patriótica!
              </div>
            </p>
          </Box>
          <Box my={2}>
          <Alert severity="error" text={<a>Usted podrá regresar a esta{" "}<a href='https://cita.vacunate.gob.do'>página</a> para consultar
              la información de su cita.</a>} />
          </Box>
        </motion.div>
      </Box>
    </>
  );
}

interface FinishStepProps {
    onBackStep: (error: string) => void;
    hideFromStepper?: boolean | undefined;
}

export default Finish;
