import { Action, createReducer } from "../../utils/redux-actions-helper";
import SubmitFormAction from "../../utils/redux-actions-helper/SubmitFormAction";
import FormState from "../../types/form_state";
import OnChangeFormAction from "../../utils/redux-actions-helper/OnChangeFormAction";
import ResetFormAction from "../../utils/redux-actions-helper/ResetFormAction";

export enum IdentityType {
  PASAPORTE = "PASAPORTE",
  CEDULA = "IDENTIFICATION",
  MINORKEY = "MINORKEY",
}

export interface RegisterModel {
  province: string;
  provinceName: string;
  town: string;
  townName: string;
  center: string;
  centerName: string;
  name: string;
  age: number;
  identityType: IdentityType;
  identification: string;
  pasaporte: string;
  date: string;
  dateFormat: string;
  createdAt: string;
  id: number;
  minorKey: string;
}

interface MinorModel {
  id: string;
  name: string;
  lastName: string;
  gender: { value: number; type: string; bool: any };
  age: number;
  BirthD: any;
}

type TFormState = FormState<RegisterModel>;
type State = TFormState & {
  step: number;
  validCedula: boolean;
  success: boolean;
  minorModel: MinorModel;
};

const initialState = {
  step: 0,
  validCedula: false,
  success: false,
  model: {
    province: "",
    provinceName: "",
    town: "",
    townName: "",
    center: "",
    centerName: "",
    createdAt: "",
    name: "",
    date: "",
    dateFormat: "",
    identification: "",
    identityType: IdentityType.CEDULA,
    age: -1,
    pasaporte: "",
    minorKey: "",
    id: 0,
  },
  minorModel: {
    id: "",
    age: 0,
    name: "",
    lastName: "",
    gender: { value: -1, type: "", bool: undefined },
    BirthD: new Date(),
  },
  errors: {},
} as State;

class SetValidCedula extends Action<State, boolean> {
  callReducer(state: State, payload: boolean): void {
    state.validCedula = payload;
  }
}


class SetSuccess extends Action<State, boolean> {
  callReducer(state: State, payload: boolean): void {
    state.success = payload;
  }
}

class SetMinor extends Action<State, MinorModel> {
  callReducer(state: State, payload: MinorModel): void {
    state.minorModel = payload;
  }
}

export const registerActions = {
  onChange: new OnChangeFormAction<RegisterModel>("REGISTER_ON_CHANGE"),
  submit: new SubmitFormAction<RegisterModel>("REGISTER_SUBMIT"),
  reset: new ResetFormAction<State>("REGISTER_RESET", initialState),
  setValidCedula: new SetValidCedula("REGISTER_SET_VALID_CEDULA"),
  setSuccess: new SetSuccess("SET_SUCCESS"),
  setMinor: new SetMinor('SET_MINOR'),
};

export const registerReducer = createReducer<State>(registerActions, initialState);
