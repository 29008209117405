import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    width: "100%",
    padding: "10px 0",
  },
  registrate: {
    color: theme.palette.primary.main,
    fontSize: "28px",
    fontWeight: 900,
    lineHeight: "24px",
    letterSpacing: "0.11px",
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: "8px",
    marginBottom: '0.35em'
  },
  p: {
    color: "#001733",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0",
    lineHeight: "22px",
    textAlign: "center",
    maxWidth: "400px"
  },
  iconRoot: {
    textAlign: "center",
  },
  stepLabel: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export const stepConnectorStyle = {
  alternativeLabel: {
    left: "calc(-50% + 14px)",
    right: "calc(50% + 14px)"
  },
  active: {
    '& $line': {
      borderColor: "#286DBD"
    }
  },
  completed: {
    '& $line': {
      borderColor: '#286DBD',
    },
  },
  line: {
    borderTopWidth: "2px",
    marginTop: "1px"
  },
};
