import { RouteType } from "./components/Routes";
import BookingRouter from "./routes/BookingRoute";
import BookingInformationRoute from "./routes/BookingInformationRoute";
import InformationRoute from "./routes/InformationRoute";
import Restore from "./routes/RestoreKey"

export const Paths = {
  BOOKING: "/",
  BOOKING_INFO: "/booking-info",
  INFO: "/info",
  RESTORE: "/restore"
};

export const routes: RouteType[] = [
  {
    path: Paths.BOOKING,
    exact: true,
    component: BookingRouter
  },
  {
    path: Paths.BOOKING_INFO,
    exact: true,
    component: BookingInformationRoute
  },
  {
    path: Paths.INFO,
    exact: true,
    component: InformationRoute
  },
  {
    path: Paths.RESTORE,
    exact: true,
    component: Restore
  },
  {
    component: BookingRouter
  },
];
