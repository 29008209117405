import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fake: {
    minHeight: "7vh",
  },
  alert: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    color: "white",
    minHeight: '150px',
    height: '150px',
    
    "& > div": {
      display: 'flex',
      // flexDirection: 'column',
      flexWrap: 'wrap',
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center',
      height: '100%',
      alignItems: "center",
    },
    "& h3": {
      marginTop: 1,
      width: "100%",
      textAlign: "center",
    },
    "& a": {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    "& button": {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  text: {
    marginTop: 0,
  },
  yellow: {
    backgroundColor: "#f8f3d6",
    color: '#9f7d41',

    "& svg": {
      color: '#9f7d41',
    }
  },
  red: {
    backgroundColor: "#ecc8c5",
    color: '#b22d2b',

    "& svg": {
      color: '#b22d2b',
    }
  },
  blue: {
    backgroundColor: "#cde8f5",
    color: '#528bb5',

    "& svg": {
      color: '#528bb5',
    }
  },
  green: {
    backgroundColor: "#def2d6",
    color: '#5a7052',

    "& svg": {
      color: '#5a7052',
    }
  },
  btnsContainer:{
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  btn:{
    margin: "auto",
  },
}));

export default useStyles;