import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router";

export interface RouteType {
  path?: string;
  exact?: boolean;
  component: React.ComponentType<any>;
}

export interface RoutersProps {
  routes: RouteType[];
}

const Routes: FunctionComponent<RoutersProps> = (props: RoutersProps) => {
  const { routes } = props;
  return (
    <Switch>
      {routes.map((route,i) => {
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
};

export default Routes;
