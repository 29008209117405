import {differenceInCalendarDays} from "date-fns";

export function isSameDay(a: Date, b: Date): boolean {
  return differenceInCalendarDays(a, b) === 0;
}

export function getDaysFromToday(dates: string[]) {
  const step1 = dates
    .filter(
      (item) =>
        !(differenceInCalendarDays(Date.parse(`${item}T00:00:00.000-04:00`), new Date()) <= 0)
    );
  return step1.map(item => new Date(`${item}T00:00:00.000-04:00`));
}