import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '10px',
    borderWidth: '1px',
    borderColor: '#c4c4c4',
    borderStyle: 'solid',
    paddingLeft: '20px',
    paddingRight: '20px',
    position: 'relative',
    marginTop: '2rem',
  },
  label: {
    transform: 'translate(0px, -10px)',
    background: 'white',
    fontWeight: 'bold',
    paddingLeft: '5px',
    paddingRight: '5px',
    position: 'absolute',
    color: theme.palette.primary.main,
    fontSize: '17px'
  }
}))

export default useStyles;