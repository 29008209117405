import React from "react";
import clsx from "clsx";
import check from "../../assets/images/Icons/check.svg";
import { StepIconProps } from "@material-ui/core";
import { useStyle } from './style';

function StepIcon(props: StepIconProps) {
  const style = useStyle();
  const { icon, active, completed } = props;
  return (
    <div className={clsx(style.root, (active || completed) && style.active)}>
      <span className={clsx(style.span, (active || completed) && style.spanActive)}>
        {completed 
          ? <img src={check} alt='' width='14px'/> 
          : icon
        }
      </span>
    </div>
  )
}

export default StepIcon;
