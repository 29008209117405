import { Box } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Alert as BaseAlert } from '@material-ui/lab';
import { useStyle } from './Alert.style';

interface Props {
  text?: any;
  severity?: any;
  verticalMargin?: number;
}

export function Alert(props: Props) {
  const style = useStyle();
  return (
    <Box display='flex' justifyContent='center' my={props.verticalMargin}>
      <BaseAlert 
        classes={style}
        severity={props.severity} 
        icon={<InfoOutlined fontSize="inherit"/>}>
        {props.text}
      </BaseAlert>
    </Box>
  )
}
