import Container from "@material-ui/core/Container";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Restore from "./RestoreKey";
import useStyles from "./Style";
import {useMediaQuery} from "@material-ui/core";
import theme from "../../theme";

function BookingRoute() {
  const styles = useStyles();

  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Fade in>
      <Container maxWidth="sm" className={styles.container}
                 style={{
                   padding: matches ? 0 : 16,
                 }}>
        <Paper className={styles.paper} style={{maxWidth: "100%"}}>
          <form noValidate autoComplete="off">
            <Grid container spacing={2} direction="column">
              <Restore/>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Fade>
  );
};

export default BookingRoute;
