import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, FormControl, Grid, TextField, } from "@material-ui/core";
import { IProvince, ITown, ICenter } from "src/types";
import { useDispatch } from "react-redux";
import { registerActions } from "src/redux/register/actions";
import { motion } from "framer-motion";
import FieldLabel from "../../FieldLabel";
import { useServerManager } from "../../ServerManagerProvider/index";
import { Autocomplete } from "@material-ui/lab";
import { useTypedSelector } from "src/redux";
import useStyles from "./styles";
import NoCenters from "src/components/NoCenter";

function Step2(props: CenterStepProps) {
  const dispatch = useDispatch();
  const {
    model: { province, provinceName, town, townName, center, centerName },
  } = useTypedSelector(state => state.register);
  const classes = useStyles();
  const [provinces, setProvinces] = useState<IProvince[]>([]);
  const [provincesFilter, setProvincesFilter] = useState<string>("");
  const [towns, setTowns] = useState<ITown[]>([]);
  const [townsFilter, setTownsFilter] = useState<string>("");
  const [centers, setCenters] = useState<ICenter[]>([]);
  const [centersFilter, setCentersFilter] = useState<string>("");
  const serverManager = useServerManager();

  useEffect(() => {
    setProvincesFilter(provinceName);
  }, []);

  useEffect(() => {
    setTownsFilter(townName);
  }, []);

  useEffect(() => {
    setCentersFilter(centerName);
  }, []);

  const isValid = useMemo(() => {
    return province && town && center
  }, [province, town, center])

  const onProvinceChanged = (event: any, newValue: string | IProvince | null) => {
    if (typeof newValue === "string")
      return;
    if (newValue) {
      dispatch(registerActions.onChange.call({ key: "province", value: newValue.provinciaId }))
    } else {
      dispatch(registerActions.onChange.call({ key: "province", value: "" }))
      dispatch(registerActions.onChange.call({ key: "town", value: "" }))
      dispatch(registerActions.onChange.call({ key: "center", value: "" }))
      setTownsFilter("");
      setCentersFilter("");
    }
  }

  const onTownChanged = (event: any, newValue: string | ITown | null) => {
    if (typeof newValue === "string")
      return;
    if (newValue) {
      dispatch(registerActions.onChange.call({ key: "town", value: newValue.municipioId }))
    } else {
      dispatch(registerActions.onChange.call({ key: "town", value: "" }))
      dispatch(registerActions.onChange.call({ key: "center", value: "" }))
      setCentersFilter("");
    }
  }

  const onCenterChanged = (event: any, newValue: string | ICenter | null) => {
    if (typeof newValue === "string")
      return;
    if (newValue) {
      dispatch(registerActions.onChange.call({ key: "center", value: newValue.center_id }))
    } else {
      dispatch(registerActions.onChange.call({ key: "center", value: "" }));
      setCentersFilter("");
    }
  }

  const onProvinceFilterChanged = (event: React.ChangeEvent<{}>, value: string,) => {
    setProvincesFilter(value);
  }

  const onTownFilterChanged = (event: React.ChangeEvent<{}>, value: string,) => {
    setTownsFilter(value);
  }

  const onSectorFilterChanged = (event: React.ChangeEvent<{}>, value: string,) => {
    setCentersFilter(value);
  }

  useEffect(() => {
    let active = true;
    (async () => {
      const response = await serverManager.loadProvincesByName(provincesFilter);
      if (active) setProvinces(response.data.results);
    })();
    return () => {
      active = false;
    };
  }, [provincesFilter]);

  useEffect(() => {
    let active = true;
    (async () => {
      const response = await serverManager.loadTowns(townsFilter, province);
      if (active) setTowns(response.data.results);
    })();
    return () => {
      active = false;
    };
  }, [townsFilter, province]);

  useEffect(() => {
    let active = true;
    (async () => {
      const response = await serverManager.loadCenters(centersFilter, town);
      if (active) setCenters(response.data.results);
    })();
    return () => {
      active = false;
    };
  }, [centersFilter, town]);

  const onBack = () => {
    dispatch(registerActions.onChange.call({ key: "province", value: "" }))
    dispatch(registerActions.onChange.call({ key: "town", value: "" }))
    dispatch(registerActions.onChange.call({ key: "center", value: "" }))
    dispatch(registerActions.onChange.call({ key: "provinceName", value: "" }))
    dispatch(registerActions.onChange.call({ key: "townName", value: "" }))
    dispatch(registerActions.onChange.call({ key: "centerName", value: "" }))
    props.onBackStep();
  }
  const onNext = () => {
    dispatch(registerActions.onChange.call({ key: "provinceName", value: provincesFilter }))
    dispatch(registerActions.onChange.call({ key: "townName", value: townsFilter }))
    dispatch(registerActions.onChange.call({ key: "centerName", value: centersFilter }))
    props.onNextStep();
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box mb={3} mt={3}>
        <FieldLabel>Provincia del centro de vacunación</FieldLabel>
        <FormControl fullWidth>
          <Autocomplete
            options={provinces}
            getOptionLabel={(option: IProvince) => option.provincia}
            onInputChange={onProvinceFilterChanged}
            onChange={onProvinceChanged}
            fullWidth freeSolo
            inputValue={provincesFilter}

            renderInput={(params) => {
              return <TextField variant="outlined" style={{ padding: "7px !important" }}
                placeholder="Escriba la provincia"
                {...params} />
            }}
          />
        </FormControl>
      </Box>
      <Box mb={3}>
        <FieldLabel>Municipio del centro de vacunación</FieldLabel>
        <FormControl fullWidth>
          <Autocomplete
            options={towns}
            getOptionLabel={(option: ITown) => option.municipio}
            onInputChange={onTownFilterChanged}
            onChange={onTownChanged}
            inputValue={townsFilter}
            fullWidth freeSolo
            disabled={!province}
            renderInput={(params) => {
              return <TextField variant="outlined"
                placeholder="Escriba el municipio"
                {...params} />
            }} />
        </FormControl>
      </Box>
      <Box mb={3}>
        <FieldLabel>Centro de vacunación</FieldLabel>
        <FormControl fullWidth>
          <Autocomplete
            options={centers}
            getOptionLabel={(option: ICenter) => option.center_name}
            onInputChange={onSectorFilterChanged}
            onChange={onCenterChanged}
            fullWidth freeSolo
            inputValue={centersFilter}
            disabled={!town}
            renderInput={(params) => {
              return <TextField {...params} variant="outlined"
                placeholder="Escriba el centro" />
            }}
          />
        </FormControl>
      </Box>

      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Button
              onClick={onBack}
              size="large"
              variant="outlined"
              fullWidth
              color="primary">
              Ir Atrás
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button onClick={onNext}
              size="large"
              fullWidth
              color="primary"
              variant="contained"
              disabled={!isValid}>
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </motion.div>
  );
}

interface CenterStepProps {
  onNextStep: () => void;
  onBackStep: () => void;
  title: string;
}

export default Step2;