import useStyles from "./styles";
import React from "react";

function Fieldset(props: any) {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <label className={classes.label}>
        {props.title}
      </label>
      {props.children}
    </div>
  )
}

export default Fieldset;