import React, {FunctionComponent} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {CssBaseline} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import "./fonts";

import {routes} from "./routes";

import theme from "./theme";
import Routes from "./components/Routes";
import Layout from "./components/Layout";
import ServerManagerProvider from "./components/ServerManagerProvider";

const App: FunctionComponent = () => {
  return (
    <ServerManagerProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Layout>
          <Router>
            <Routes routes={routes}/>
          </Router>
        </Layout>
      </ThemeProvider>
    </ServerManagerProvider>
  );
};

export default App;
