import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles";

const spacing = 8;
const controlsFontSize = 15;

export default createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#003876",
      light: "#04397E"
    },
    secondary: {
      main: "#3d4453"
    },
    warning: {
      main: "#f7931e"
    },
    error: {
      main: "#ee4343"
    },
    success: {
      main: "#56df34"
    },
    text: {
      primary: "#001733"
    },
  },
  spacing: spacing,
  typography: {
    fontFamily: "Nunito",
    link: {
      fontSize: 15
    }
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        marginRight: -spacing * 2.5
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: controlsFontSize
      }
    },
    MuiFormControl: {
      root: {
        marginTop: "5px",
      }
    },
    MuiInput: {
      root: {
        fontSize: controlsFontSize
      }
    },
    MuiBadge: {
      badge: {
        padding: 0
      }
    },
    MuiButton: {
      root: {
        fontWeight: "bold",
        borderRadius: "10px",
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "5px",
        fontSize: "17px"
      }
    },
    MuiAlert: {
      standardError: {
        color: "#B22D2B",
        backgroundColor: "#ECC8C5"
      },
      standardWarning: {
        color: "#9F7D41",
        backgroundColor: "#F8F3D6"
      },
      standardInfo: {
        color: "#528BB5",
        backgroundColor: "#CDE8F5"
      },
      standardSuccess: {
        color: "#5A7052",
        backgroundColor: "#DEF2D6"
      },
    },
  },
} as ThemeOptions);
