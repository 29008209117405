import React, { PropsWithChildren } from "react";
import { Box, Button, Container, Paper, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { motion } from "framer-motion";
import useStyles from "./StatusMessageStyles";
import errorImage from "../../assets/images/error.png";
import confirmacion from "../../assets/images/confirmacion.png";

function StatusMessage(props: StatusMessageProps) {
  const classes = useStyles();

  const severity = props.severity ? props.severity : "info";

  const renderIcon = () => {
    switch (severity) {
      case "error":
        return (
          <motion.img
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            src={errorImage}
            className={classes.img}
            alt=""
          />
        );
      case "success":
        return (
          <motion.img
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            src={confirmacion}
            className={classes.img}
            alt=""
          />
        );
      default:
        return (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          >
            <Info color="primary" className={classes.icon} />
          </motion.div>
        );
    }
  };

  return (
    <Container
      maxWidth="sm"
      component={Paper}
      className={classes.cardContainer}
      {...(props.disableElevation && { elevation: 0 })}
    >
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 1,
          staggerChildren: 0.3,
          delayChildren: 0.5,
        }}
        className={classes.container}
      >
        {renderIcon()}
        <Box>
          <Typography
            variant="h6"
            style={{
              color: "#1F305B",
              fontSize: "21",
              fontWeight: "bold",
              letterSpacing: "0.6px",
              lineHeight: "25px",
              textAlign: "center",
            }}
          >
            {props.title}
          </Typography>
        </Box>
        <Box mt={1}>
          <p
            style={{
              color: "#1F305B",
              fontSize: "16px",
              letterSpacing: "0.46px",
              textAlign: "center",
              lineHeight: "28px",
            }}
          >
            {props.children}
          </p>
        </Box>
      </motion.div>

      {props.resendAction && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 1,
            staggerChildren: 0.3,
            delayChildren: 0.5,
          }}
          className={classes.container}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={props.resendAction && props.resendAction}
          >
            Volver a enviar datos
          </Button>
        </motion.div>
      )}
    </Container>
  );
}

export interface StatusMessageProps extends PropsWithChildren<any> {
  resendAction?: () => void | undefined;
  severity?: "error" | "success" | "info";
  title: string;
  disableElevation?: boolean;
}

export default StatusMessage;
