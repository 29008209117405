import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  btnsContainer:{
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  btn:{
    margin: "auto",
  },
}));

export default useStyles;