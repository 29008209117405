import useStyles from "./style";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import FileCopy from "@material-ui/icons/FileCopy";

export default function SimpleCard(props: any) {
  const classes = useStyles();

  const handleCopyClick = (e:any)=>{
    navigator.clipboard.writeText(props.MinorKey)
    .then(()=>{
      console.log('copiado')
    })
    .catch((err)=>{
      console.error(err)
    })
  }
  return (
    <Card className={classes.root}>
      <Typography className={classes.fields}>
        Clave única: <span className={classes.bold}>{props.MinorKey}</span>
        <IconButton
         onClick={handleCopyClick}
         className={classes.btn}>
          <FileCopy color="inherit" />
        </IconButton>
      </Typography>
      {props.identification && (
        <Typography className={classes.fields}>
          Identificación: {` ${props.identification}`}
        </Typography>
      )}
    </Card>
  );
}
