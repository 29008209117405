import { ReactNode } from "react";
import useStyles from "./styles";

function FieldLabel(props: {children: ReactNode}) {
  const classes = useStyles();

  return <label className={classes.fieldLabel}>{props.children}</label>;
}

export default FieldLabel;
