import Action from "./Action";

class ResetFormAction<State> extends Action<State, null> {
  constructor(callType: string, private initialState: State) {
    super(callType);
  }

  callReducer = (state: State): void => {
    Object.assign(state, this.initialState);
  };
}

export default ResetFormAction;
