import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { motion } from "framer-motion";
import { registerActions } from "../../../redux/register/actions";
import moment from "moment";
import FieldLabel from "../../FieldLabel";
import { useServerManager } from "../../ServerManagerProvider/index";
import { Paths } from "../../../routes";
import { useTypedSelector } from "../../../redux";
import useStyles from "./styles";
import IStatusCheck, { StatusName } from "../../../types/IStatusCheck";
import clsx from "clsx";
import NoCenters from "../../NoCenter";

function IdentityConfirmationStep(props: WelcomeStepProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const serverManager = useServerManager();

  const {
    model,
    model: { identification, minorKey },
  } = useTypedSelector((store) => store.register);

  const [errorContent, setErrorContent] = useState<any>(null);
  const [loadingApiCheck, setLoadingApiCheck] = useState<boolean>(false);
  const [status, setStatus] = useState<IStatusCheck | false>(false);

  const [noCentersAlert, setNoCentersAlert] = useState<boolean>(false);

  const setAppointmentInfo = (data: IStatusCheck | false) => {
    if (!data) return;

    dispatch(
      registerActions.onChange.call({
        key: "age",
        value: data.registerInfo.Age,
      })
    );
    dispatch(
      registerActions.onChange.call({
        key: "name",
        value: data.registerInfo.Name,
      })
    );
    dispatch(
      registerActions.onChange.call({
        key: "identification",
        value: data.registerInfo.Identification,
      })
    );
    dispatch(
      registerActions.onChange.call({
        key: "center",
        value: data.appointmentInfo.Center?.name?? '',
      })
    );
    dispatch(
      registerActions.onChange.call({
        key: "date",
        value: data.appointmentInfo.Date,
      })
    );
    dispatch(
      registerActions.onChange.call({
        key: "dateFormat",
        value: data.appointmentInfo.DateFormat,
      })
    );
  };

  const setRegisterInfo = (data: IStatusCheck | false) => {
    if (!data) return;
    dispatch(
      registerActions.onChange.call({
        key: "age",
        value: data.registerInfo.Age,
      })
    );
    dispatch(
      registerActions.onChange.call({
        key: "identification",
        value: data.registerInfo.Identification,
      })
    );
    dispatch(
      registerActions.onChange.call({
        key: "name",
        value: data.registerInfo.Name,
      })
    );
    dispatch(
      registerActions.onChange.call({
        key: "id",
        value: data.registerInfo.id,
      })
    );
    dispatch(
      registerActions.onChange.call({
        key: "createdAt",
        value: data.registerInfo.createdAt,
      })
    );
  };

  const onChange = async (
    key: "identification" | "pasaporte" | "identityType" | "minorKey",
    value: any
  ) => {
    if (model[key] === value) return;
    value = value.toUpperCase();
    if (key === "minorKey" && value.length <= 8)
      dispatch(registerActions.onChange.call({ key, value }));
    if (key === "identification" && value.length <= 13)
      dispatch(registerActions.onChange.call({ key, value }));

    setStatus(false);
    let fetchCondition = key === "minorKey" && value.length === 8;
    if (fetchCondition) {
      setLoadingApiCheck(true);

      const check = await serverManager.checkStatus(value);
      let status = 0;
      let minorStatus = 0;
      if (check) status = check.status_id;

      if (!(status + minorStatus)) {
        if (check) {
          switch (check.status_name) {
            case StatusName.UNREGISTERED:
              setStatus(check);
              break;
            case StatusName.PHASE_NOT_ACTIVE:
              setStatus(check);
              break;
            case StatusName.WITHOUT_APPOINTMENT:
              setStatus(check);
              setRegisterInfo(check);
              break;
            case StatusName.WITH_APPOINTMENT:
              setAppointmentInfo(check);
              setStatus(check);
              break;
          }
        }
      } else {
        if (status && check) {
          switch (check.status_name) {
            case StatusName.UNREGISTERED:
              setStatus(check);
              break;
            case StatusName.PHASE_NOT_ACTIVE:
              setStatus(check);
              break;
            case StatusName.WITHOUT_APPOINTMENT:
              setStatus(check);
              setRegisterInfo(check);
              break;
            case StatusName.WITH_APPOINTMENT:
              setAppointmentInfo(check);
              setStatus(check);
              break;
          }
        }
      }
      setLoadingApiCheck(false);
    }
  };

  const handleCreateAppointment = async (e: any) => {
    let { data: centers } = await serverManager.loadCenters("", "");
    if (centers.count === 0) {
      setNoCentersAlert(true);
    } else {
      props.onNextStep();
    }
  };

  const renderStatus = () => {
    if (!status) return;
    switch (status.status_name) {
      case StatusName.UNREGISTERED:
        return (
          <Alert icon={false} className={clsx(classes.alert, classes.yellow)}>
            <h3 className={classes.text}>Clave única no encontrada</h3>
            <Button
              variant="contained"
              onClick={() => history.push("/restore")}
            >
              Recuperar Clave
            </Button>
            ó
            <Button
              variant="contained"
              href="https://registro.vacunate.gob.do/"
              target="_black"
            >
              REGISTRAR PACIENTE
            </Button>
          </Alert>
        );
      case StatusName.PHASE_NOT_ACTIVE:
        return (
          <Alert icon={false} className={clsx(classes.alert, classes.red)}>
            <h3 className={classes.text}>
              Paciente registrado, pero le corresponde vacunarse en:
            </h3>
            <h3 style={{ textAlign: "center" }}>{status.phase_name}</h3>

            <Button
              variant="contained"
              href="https://vacunate.gob.do/#fases"
              target="_black"
            >
              VER FASES
            </Button>
          </Alert>
        );
      case StatusName.WITHOUT_APPOINTMENT:
        return (
          <Alert icon={false} className={clsx(classes.alert, classes.blue)}>
            <h3 style={{ marginBottom: 0 }}>
              Paciente registrado, pero aún no tiene cita.
            </h3>
            <br />
            <br />
            <Button onClick={handleCreateAppointment} variant="contained">
              AGENDAR CITA
            </Button>
          </Alert>
        );
      case StatusName.WITH_APPOINTMENT:
        return (
          <Alert icon={false} className={clsx(classes.alert, classes.green)}>
            <h3>Paciente registrado y con cita.</h3>
            <Button
              component={RouterLink}
              variant="contained"
              to={Paths.BOOKING_INFO}
            >
              VER CITA
            </Button>
          </Alert>
        );
    }
  };

  const getTimeLeftError = (timeLeftNumber: number) => {
    var duration = moment.duration(timeLeftNumber, "milliseconds");
    const hoursMessage = duration.hours() <= 0 ? "hora" : "horas";
    const minutesMessage = duration.minutes() <= 0 ? "minuto" : "minutos";
    return `${duration.hours()} ${hoursMessage} y ${duration.minutes()} ${minutesMessage}`;
  };

  const renderForm = (
    <>
      <Box my={3}>
        <FieldLabel>Clave única: </FieldLabel>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="uniquekey"
            placeholder="Escriba su clave única"
            value={minorKey}
            onChange={(e) => onChange("minorKey", e.target.value)}
            endAdornment={
              <>
                {loadingApiCheck && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                )}
              </>
            }
          />
        </FormControl>{" "}
      </Box>
      {!status && (
        <Box className={classes.btnsContainer}>
          <Button
            color="primary"
            variant={"contained"}
            className={classes.btn}
            onClick={() => history.push("/restore")}
          >
            Recuperar Clave
          </Button>
        </Box>
      )}
    </>
  );

  const hasData = () => {
    if (hasError()) return false;
    return !loadingApiCheck && identification;
  };

  const hasError = (): boolean => {
    return Boolean(errorContent);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {renderForm}

      <Box my={2} style={{ minHeight: "100px" }}>
        {renderStatus()}
      </Box>
      <NoCenters open={noCentersAlert} onClose={setNoCentersAlert}></NoCenters>
    </motion.div>
  );
}

interface WelcomeStepProps {
  onNextStep: () => void | any;
  title: string;
}

export default IdentityConfirmationStep;
