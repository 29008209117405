import produce from "immer";
import { ajaxRequestInit } from "../../types/ajax_request";

const isComplexAction = (action: any) => {
  return !!action.successType;
};

const createReducer = <T>(actions: Record<string, any>, initialState: T) => {
  return (oldState = initialState, action: any): T =>
    produce(oldState, (state: T) => {
      const managers: Record<string, any> = {};
      for (const key of Object.keys(actions)) {
        const actionObj = actions[key];
        managers[actionObj.callType] = actionObj.callReducer;
        if (isComplexAction(actionObj)) {
          managers[actionObj.successType] = actionObj.successReducer;
          managers[actionObj.failedType] = actionObj.failedReducer;
          managers[actionObj.resetType] = actionObj.resetReducer;
        }
      }

      const actionManager = managers[action.type];
      if (actionManager) {
        actionManager(state, action.payload);
      }
    });
};

export const createReducerAjax = (actions: Record<string, any>) => {
  return createReducer(actions, { ...ajaxRequestInit });
};

export default createReducer;
