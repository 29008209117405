import React from "react";
import logo from "../../assets/images/Logos/Vacunaterd.png";
import { stepConnectorStyle, useStyles } from "./styles";
import { withStyles } from "@material-ui/styles";

import { 
  Box, 
  Step, 
  StepConnector, 
  StepLabel, 
  Stepper
} from "@material-ui/core";
import StepIcon from "../StepIcon";

const Connector = withStyles(stepConnectorStyle)(StepConnector);

function BookingSteper(props: BookingSteperProps) {
  const classes = useStyles();

  const renderStep = () => {
    return props.children[props.step];
  };

  const isCompleted = (index: number) => {
    if (props.step === props.children.length - 1) return true;

    return props.step > index;
  };  

  return (
    <Box
      display="flex"
      flexDirection="column"
      py={[1, 5]}
      px={[1, 7]}
      justifyContent="center"
      alignItems="center"
    >
      {props.step < props.children.length - 1 && (
        <>
          <img src={logo} alt="" />
          <Box mt={1}>
            <h6
              className={classes.registrate}
            >
              ¡Agende su cita!
            </h6>
          </Box>
          <p className={classes.p}>
            Ahora complete la información correspondiente a su cita en el
            Programa Nacional de Vacunación.
          </p>
        </>
      )}
      
      {props.step < props.children.length - 1 && (
        <Stepper
          className={classes.stepper}
          activeStep={props.step}
          alternativeLabel
          connector={<Connector/>}
        >
          {props.children.map((item: any, index) => {
            if (item.props.hideFromStepper) return null;
            return (
              <Step key={index} completed={isCompleted(index)}>
                <StepLabel StepIconComponent={StepIcon}/>
              </Step>
            );
          })}
        </Stepper>
      )}
      <div style={{ width: "100%" }}>{renderStep()}</div>
    </Box>
  );
}

interface BookingSteperProps {
  children: React.ReactNodeArray;
  step: number;
  hideFromStepper?: boolean | undefined;
}

export default BookingSteper;


// const renderError = () => {
  //   if (!loading && error)
  //     return (
  //       <>
  //         <Box
  //           display="flex"
  //           flexDirection="column"
  //           justifyContent="center"
  //           alignItems="center"
  //         >
  //           <motion.img
  //             initial={{ opacity: 0, scale: 0 }}
  //             animate={{ opacity: 1, scale: 1 }}
  //             transition={{ duration: 1 }}
  //             src={errorImage}
  //             alt=""
  //           />
  //           <motion.div
  //             initial={{ opacity: 0, y: 50 }}
  //             animate={{ opacity: 1, y: 0 }}
  //             transition={{
  //               duration: 1,
  //               staggerChildren: 0.3,
  //               delayChildren: 0.5,
  //             }}
  //           >
  //             <Box mt={4}>
  //               <Typography
  //                 variant="h6"
  //                 style={{
  //                   color: "#1F305B",
  //                   fontSize: "21",
  //                   fontWeight: "bold",
  //                   letterSpacing: "0.6px",
  //                   lineHeight: "25px",
  //                   textAlign: "center",
  //                 }}
  //               >
  //                 Ha ocurrido un error
  //               </Typography>
  //             </Box>
  //             <Box mt={1}>
  //               <p
  //                 style={{
  //                   color: "#1F305B",
  //                   fontSize: "16px",
  //                   letterSpacing: "0.46px",
  //                   textAlign: "center",
  //                   lineHeight: "28px",
  //                 }}
  //               >
  //                 No es un problema con sus datos...
  //               </p>
  //             </Box>
  //           </motion.div>
  //           <motion.div
  //             initial={{ opacity: 0, y: 50 }}
  //             animate={{ opacity: 1, y: 0 }}
  //             transition={{
  //               duration: 1,
  //               staggerChildren: 0.3,
  //               delayChildren: 0.5,
  //             }}
  //           >
  //             <Button
  //               variant="contained"
  //               color="primary"
  //               onClick={() => sendData()}
  //             >
  //               Volver a enviar datos
  //             </Button>
  //           </motion.div>
  //         </Box>
  //         <motion.div
  //           initial={{ opacity: 0, y: 100 }}
  //           animate={{ opacity: 1, y: 0 }}
  //           transition={{
  //             duration: 1,
  //             delay: 0.5,
  //             staggerChildren: 0.3,
  //             delayChildren: 0.5,
  //           }}
  //         ></motion.div>
  //       </>
  //     );
  // };