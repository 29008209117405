import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    minWidth: "50px",
    minHeight: "50px",
  },
  img: {
    maxWidth: "50px",
    marginBottom: "3px"
  }
}));

export default useStyles;