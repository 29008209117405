import { makeStyles } from '@material-ui/core/styles';
import theme from 'src/theme';

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    borderRadius: '1rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  fields: {
    fontSize: theme.spacing(2),
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  btn: {
    marginLeft: "auto",
    marginRight: theme.spacing(1),
    color: "white"
  }
});

export default useStyles;