import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: "100%",
    minHeight: "80vh",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
    },
  },
  p: {
    color: "#1F305B",
    fontSize: "16px",
    letterSpacing: "0.46px",
    textAlign: "justify",
    lineHeight: "28px",
  },
  center: {
    textAlign: "center"
  }
}));

export default useStyles;