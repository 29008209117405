import React from "react";
import {AppBar, Container, Toolbar, useMediaQuery, useTheme} from "@material-ui/core";
import presidenciaLogo from "../../assets/images/Logos/Presidencia.svg";
import useStyles from "./LayoutStyles";

function PublicLayout(props: LayoutProps) {
  const {children} = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.main}>
      <AppBar
        variant="outlined"
        position="static"
        color="transparent"
        className={classes.appbar}
      >
        <Toolbar className={classes.toolbar}>
          <Container maxWidth="md">
            <img
              src={presidenciaLogo}
              title="Gobierno de la República Dominicana"
              alt="gobierno"
            />
          </Container>
        </Toolbar>
      </AppBar>
      <Container
        className={classes.container}
        style={{
          padding: matches ? 0 : 16
        }}
      >
        {children}
      </Container>
    </div>
  );
}

export interface LayoutProps {
  children: any;
}

export default PublicLayout;
