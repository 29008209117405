import React, {useState} from "react";
import {useHistory} from 'react-router-dom'

import BookingSteper from "../BookingStepper";
import IdentityConfirmationStep from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3/Step3";
import ConfirmationStep from "./Step4/ConfirmationStep";
import FinishStep from "./Finish";

import {Paths} from "../../routes";
import {SHOW_INFORMATIVE_STEP} from "../../Config";

function Booking() {
  const [step, setStep] = useState(0);
  const [error, setError] = useState("");

  const history = useHistory();

  const onNextStep = () => {
    if (SHOW_INFORMATIVE_STEP)
      history.push(Paths.INFO)
    setStep(step + 1);
  };

  const onBackStep = () => {
    setStep(step - 1);
  };

  return (
    <div>
      <BookingSteper step={step}>
        <IdentityConfirmationStep
          onNextStep={onNextStep}
          title="Confirmación de identidad"
        />
        <Step2
          onNextStep={onNextStep}
          onBackStep={onBackStep}
          title="Centro de vacunación"
        />
        <Step3
          onNextStep={onNextStep}
          onBackStep={onBackStep}
          title="Fecha de vacunación"
          error={error}
        />
        <ConfirmationStep
          onNextStep={onNextStep}
          onBackStep={onBackStep}
          title="Confirmación"
        />
        <FinishStep
          onBackStep={onBackStep}
          hideFromStepper={true}
        />
      </BookingSteper>
    </div>
  );
}

export default Booking;
