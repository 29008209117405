import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: "500px",
  },
  paper: {
    padding: theme.spacing(2),
    width: "100%",
    borderRadius: "10px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
    },
  },
}));

export default useStyles;
